body {
  margin-top: 2em;
}

.flex-container {
  display: flex;
  width: 80%;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  margin: 0 auto;
  column-gap: 1em;
}

#GoogleForm {
  display: flex;
  align-items: center;
  justify-content: center;
}

#Logo, #Wizard {
  width: 150px;
}

th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

#requestForm {
  width: 60%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color:beige;
  border-radius: 1em;
  padding: 2em;
  z-index: 2;
  display: none;
}

.requestFormTitle {
  margin-bottom: 1em;
}

#requestForm.open {
  display: block;
}

.submitForm {
  margin-top: 1em;
  margin-right: 1em;
}

#FontSize {
  font-size: 1rem;
}

#DataTable {
  font-size: 1em;
}

/* the GST column with no newline break */
#DataTable td:nth-child(5) {
  white-space: nowrap;
}

#BiggerFont {
  font-size: 1.5rem;
  margin: 0 0 0 10px;
}
